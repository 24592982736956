import type { Metric } from 'web-vitals'

export default function sendWebVitalsResultsToAnalytics(metric: Metric) {
  window.dataLayer.push({
    event: 'coreWebVitals',
    tipo_projeto: 'lno-frontend-concierge',
    page_location: window.location.href,
    ambiente: 'deslogado',
    webVitalsMeasurement: {
      name: metric.name,
      id: metric.id,
      value: metric.value,
      delta: metric.delta,
      valueRounded: Math.round(metric.value),
      deltaRounded: Math.round(metric.delta),
    },
  })
}
