import { default as NextError } from 'next/error'
import type { NextPageContext } from 'next'
import { setNoCacheControl } from '@lno/core/utils/cache'

export interface ErrorProps {
  statusCode: number
}

const Error = ({ statusCode }: ErrorProps) => {
  return (
    <NextError
      statusCode={statusCode}
      withDarkMode={false}
      title="Ops, algo deu errado. Infelizmente não foi possível carregar essa página."
    />
  )
}

Error.getInitialProps = async ({ res, err }: NextPageContext) => {
  setNoCacheControl(res)
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404
  return { statusCode }
}

export default Error
