export const CUSTOM_ACTION = {
  ERROR_REPORTING: 'error_reporting',
  OPEN_MODAL_LOGIN: 'open_modal_login',
  CLOSE_MODAL_LOGIN: 'close_modal_login',
  LOGIN_SIGN_IN_STEP: 'login_sign_in_step',
  LOGIN_ERROR_AGE: 'login_error_age',
  LOGIN_ERROR_LIMIT_ATTEMPTS: 'login_error_limit_attempts',
  LOGIN_ERROR_INVALID_DATA: 'login_error_invalid_data',
  LOGIN_ERROR_GENERAL: 'login_error_general',
  LOGIN_NOTIFICATION_STEP: 'login_notification_step',
  LOGIN_SHOW_TERMS: 'login_show_terms',
} as const
