const SIZES = {
  bigDesktop: 1168,
  get bigDesktopDown() {
    return this.bigDesktop - 1
  },
  desktop: 992,
  get desktopDown() {
    return this.desktop - 1
  },
  tablet: 600,
  get tabletDown() {
    return this.tablet - 1
  },
  mediumMobile: 360,
} as const

export const breakpoints = {
  bigDesktop: `(min-width: ${SIZES.bigDesktop}px)`,
  bigDesktopDown: `(max-width: ${SIZES.bigDesktopDown}px)`,

  desktop: `(min-width: ${SIZES.desktop}px)`,
  desktopDown: `(max-width: ${SIZES.desktopDown}px)`,

  tablet: `(min-width: ${SIZES.tablet}px)`,
  tabletOnly: `(min-width: ${SIZES.tablet}px) and (max-width: ${SIZES.desktopDown}px)`,
  tabletDown: `(max-width: ${SIZES.tabletDown}px)`,

  mediumMobile: `(min-width: 360px)`,
} as const

export default breakpoints
