import type { NextPageContext } from 'next'

export const setCacheControl = (res: NextPageContext['res']) => {
  if (!res) return
  if (res.statusCode >= 400) return setNoCacheControl(res)
  res.setHeader(
    'Cache-Control',
    's-maxage=600, stale-while-revalidate=59, stale-if-error=1200'
  )
}

export const setNoCacheControl = (res: NextPageContext['res']) => {
  if (!res) return
  res.setHeader(
    'Cache-Control',
    'no-cache, no-store, max-age=0, must-revalidate'
  )
}
