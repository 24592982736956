export const LN_WEBFILES_URL = process.env.LN_STATIC_WEBFILES_URL!

export const LN_BASE_PATH = process.env.LN_BASE_PATH || ''

export const LN_PORTAL_URL = process.env.LN_PORTAL_URL!

export const LN_AUTH_URL = process.env.LN_AUTH_URL!

export const LN_AUTH_COOKIE_DOMAIN = process.env.LN_AUTH_COOKIE_DOMAIN!

export const LNO_APP_ID = 'lno-web'

export const IS_CAMPAIGN_SEASON = process.env.IS_CAMPAIGN_SEASON === 'true'

const ENV = process.env.LN_ENV!

export const LN_ENV = {
  CURRENT: ENV,
  IS_LOCAL: ENV === 'localhost',
  IS_MOCK: ENV === 'mock',
  is_DEV: ENV === 'development',
  IS_HOMOLOG: ENV === 'homologation',
  IS_PROD: ENV === 'production',
}
