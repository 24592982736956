type stringOrNumberOrNull = string | number | null

type TypographyParams = [
  stringOrNumberOrNull?, // fontWeight
  stringOrNumberOrNull?, // lineHeight
  stringOrNumberOrNull? // letterSpacing
]

export type TypographyFn = (...params: TypographyParams) => string

type BuildTypographyParams = {
  defaultFontSize: number
  defaultLineHeight: string | number
  defaultFontWeight?: string | number
  defaultLetterSpacing?: string | number
  params: TypographyParams
}

const buildTypography = ({
  defaultFontSize,
  defaultFontWeight,
  defaultLineHeight,
  defaultLetterSpacing,
  params,
}: BuildTypographyParams) => {
  const [fontWeight, lineHeight, letterSpacing] = params

  let css = `
    font-size: ${defaultFontSize / 16}rem;
    line-height: ${lineHeight || defaultLineHeight};
  `

  if (defaultFontWeight || fontWeight) {
    css += `font-weight: ${fontWeight || defaultFontWeight};`
  }

  if (defaultLetterSpacing || letterSpacing) {
    css += `letter-spacing: ${letterSpacing || defaultLetterSpacing};`
  }

  return css
}

export const displayDesktop = (...params: TypographyParams) =>
  buildTypography({
    defaultFontSize: 48,
    defaultLineHeight: '3.25rem',
    defaultLetterSpacing: '-0.025rem',
    defaultFontWeight: 'bold',
    params,
  })

export const displayMobile = (...params: TypographyParams) =>
  buildTypography({
    defaultFontSize: 40,
    defaultLineHeight: '2.75rem',
    defaultLetterSpacing: '-0.025rem',
    defaultFontWeight: 'bold',
    params,
  })

export const headingLDesktop = (...params: TypographyParams) =>
  buildTypography({
    defaultFontSize: 40,
    defaultLineHeight: '2.75rem',
    defaultLetterSpacing: '-0.022727272727273rem',
    defaultFontWeight: 'bold',
    params,
  })

export const headingLMobile = (...params: TypographyParams) =>
  buildTypography({
    defaultFontSize: 32,
    defaultLineHeight: '2.25rem',
    defaultLetterSpacing: '-0.022727272727273rem',
    defaultFontWeight: 'bold',
    params,
  })

export const headingMDesktop = (...params: TypographyParams) =>
  buildTypography({
    defaultFontSize: 32,
    defaultLineHeight: '2.25rem',
    defaultLetterSpacing: '-0.022222222222222rem',
    defaultFontWeight: 'bold',
    params,
  })

export const headingMMobile = (...params: TypographyParams) =>
  buildTypography({
    defaultFontSize: 24,
    defaultLineHeight: '1.75rem',
    defaultLetterSpacing: '-0.022222222222222rem',
    defaultFontWeight: 'bold',
    params,
  })

export const headingSDesktop = (...params: TypographyParams) =>
  buildTypography({
    defaultFontSize: 24,
    defaultLineHeight: '1.75rem',
    defaultLetterSpacing: '-0.021428571428571rem',
    defaultFontWeight: 'bold',
    params,
  })

export const headingSMobile = (...params: TypographyParams) =>
  buildTypography({
    defaultFontSize: 20,
    defaultLineHeight: '1.5rem',
    defaultLetterSpacing: '-0.021428571428571rem',
    defaultFontWeight: 'bold',
    params,
  })

export const headingXSDesktop = (...params: TypographyParams) =>
  buildTypography({
    defaultFontSize: 20,
    defaultLineHeight: '1.5rem',
    defaultFontWeight: 'bold',
    params,
  })

export const headingXSMobile = (...params: TypographyParams) =>
  buildTypography({
    defaultFontSize: 18,
    defaultLineHeight: '1.5rem',
    defaultFontWeight: 'bold',
    params,
  })

export const subheading = (...params: TypographyParams) =>
  buildTypography({
    defaultFontSize: 18,
    defaultLineHeight: '1.25rem',
    defaultFontWeight: 'bold',
    params,
  })

export const bodyM = (...params: TypographyParams) =>
  buildTypography({
    defaultFontSize: 16,
    defaultLineHeight: '1.5rem',
    params,
  })

export const bodyP = (...params: TypographyParams) =>
  buildTypography({
    defaultFontSize: 14,
    defaultLineHeight: '1.25rem',
    params,
  })

export const caption = (...params: TypographyParams) =>
  buildTypography({
    defaultFontSize: 12,
    defaultLineHeight: '1rem',
    params,
  })

export const typography = {
  displayDesktop,
  displayMobile,
  headingLDesktop,
  headingLMobile,
  headingMDesktop,
  headingMMobile,
  headingSDesktop,
  headingSMobile,
  headingXSDesktop,
  headingXSMobile,
  subheading,
  bodyM,
  bodyP,
  caption,
}

export default typography
