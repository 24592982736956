import { LN_BASE_PATH, LN_PORTAL_URL, LN_WEBFILES_URL } from './env'

export { LN_AUTH_URL, LN_PORTAL_URL } from './env'

export const LN_WEBFILES_LNO = `${LN_WEBFILES_URL}/limpa-nome`

export const LN_WEBFILES_LANDING = `${LN_WEBFILES_URL}/limpa-nome/landing-page`

export const LN_WEBFILES_COMMON_PRODUCTS_URL = `${LN_WEBFILES_URL}/common/products`

export const LN_WEBFILES_ICONS = `${LN_WEBFILES_LNO}/icons`

export const LN_LANDING_URL = `${LN_PORTAL_URL}/limpa-nome-online`

export const LN_LOGIN_URL = `${LN_PORTAL_URL}/entrar?product=limpa-nome&redirectUrl=/limpa-nome-online/area-cliente`

export const BASE_URL = LN_PORTAL_URL + LN_BASE_PATH

export const SERASA_LOGIN_URL = `${LN_PORTAL_URL}/limpa-nome-online/entrar?product=limpa-nome&redirectUrl=/limpa-nome-online/area-cliente`

export const SERASA_LOGIN_URL_WEB = `${LN_PORTAL_URL}/limpa-nome-online/entrar?product=portal&redirectUrl=/area-cliente/solucoes/negociar`

export const SERVER_API_URL = `${LN_LANDING_URL}/api`

export const MOCK_URL = process.env.LN_MOCK_HOST

export const LOGGED_AREA_URL =
  process.env.LN_LOGGED_AREA_URL || `${LN_LANDING_URL}/area-cliente`
