import { useEffect } from 'react'
import type { AppProps } from 'next/app'

import { Monitoring } from '@lno/modules/monitoring'
import { RootErrorBoundary } from '@lno/components/RootErrorBoundary'

import '@lno/core/styles/reset.css'
import '@lno/core/styles/colors.css'
import '@lno/core/styles/global.css'
import '@lno/core/styles/sizes.css'
import '@lno/core/styles/utilities/_index.scss'
import Script from 'next/script'
import { WebVitals } from '@lno/core/components/WebVitals'

function CustomApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    Monitoring.init()
  }, [])

  return (
    <RootErrorBoundary>
      <Component {...pageProps} />
      {process.env.NODE_ENV === 'production' && (
        <>
          <Script
            strategy="afterInteractive"
            id="gtm-script"
            dangerouslySetInnerHTML={{
              __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','GTM-5XS8NFS');`,
            }}
          />
          <WebVitals />
        </>
      )}
    </RootErrorBoundary>
  )
}

export default CustomApp
