import { CUSTOM_ACTION } from '../constants'

export const createAction = (actionName: string, leaveAction = false) => {
  if (!window.dtrum) {
    if (process.env.LN_DYNATRACE_LOG) {
      console.log(actionName)
    }

    return
  }

  const action = window.dtrum.enterAction(`@concierge/${actionName}`)

  if (leaveAction) {
    window.dtrum.leaveAction(action)
  } else {
    return action
  }
}

export const closeAction = (action: number) => {
  if (!window.dtrum) return

  return window.dtrum.leaveAction(action)
}

export const reportError = (error: any) => {
  if (!window.dtrum) {
    console.log(error)
    return
  }

  const action = createAction(CUSTOM_ACTION.ERROR_REPORTING) as number
  window.dtrum.reportError(error, action)
  closeAction(action)
}

export const enableManualPageDetection = () => {
  if (!window.dtrum) {
    return
  }

  window.dtrum.enableManualPageDetection()
}

export const setPage = (page: string) => {
  if (!window.dtrum) return

  window.dtrum.setPage({
    name: `concierge-{${page}}`,
    group: 'ecs-lno-frontend-concierge',
  })
}
