import { Component } from 'react'

import { reportError } from '@lno/modules/dynatrace'
import { Monitoring } from '@lno/modules/monitoring'

import Error from 'pages/_error'
import type { ErrorProps } from 'pages/_error'

export class RootErrorBoundary extends Component<
  Partial<ErrorProps>,
  { hasError: boolean }
> {
  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: any) {
    Monitoring.reportError(error as Error)
    reportError(error)
  }

  render() {
    return this.state?.hasError ? (
      <Error statusCode={500} {...this.props} />
    ) : (
      this.props.children
    )
  }
}
