import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum-slim'
import { LN_ENV } from '@lno/core/constants'
import {
  ALLOWED_TRACING_ORIGINS,
  APP_VERSION,
  DD_APPLICATION_ID,
  DD_CLIENT_TOKEN,
  DD_ENABLED,
  DD_ENV,
  DD_IS_SESSION_REPLAY_ENABLED,
  DD_SAMPLE_RATES,
  DD_SITE,
  PROJECT_NAME,
} from './constants'

type LogLevel = 'info' | 'warn' | 'debug' | 'error'

function Monitoring() {
  const isProd = LN_ENV.IS_PROD
  const DD_SERVICE_NAME = `${DD_ENV}---${PROJECT_NAME}`

  function validateInitConfiguration(): boolean | never {
    if (isProd && !APP_VERSION?.length) {
      const error = new Error('Invalid datadog app version.')
      console.error(error)
      return false
    }

    const areDatadogEnvsSet = Boolean(
      DD_ENV && DD_APPLICATION_ID && DD_CLIENT_TOKEN
    )

    if (!areDatadogEnvsSet) {
      const error = new Error('Invalid datadog envs.')
      console.error(error)
      return false
    }

    return true
  }

  function init() {
    const isDatadogEnabled = DD_ENABLED === 'true'

    if (!isDatadogEnabled) return

    if (!validateInitConfiguration()) return

    initRum()
    if (DD_IS_SESSION_REPLAY_ENABLED) initSessionReplayRecorder()
    initLogs()
  }

  const commonConfig = {
    clientToken: DD_CLIENT_TOKEN,
    site: DD_SITE,
    service: DD_SERVICE_NAME,
    env: DD_ENV,
    version: APP_VERSION,
  }

  function initRum() {
    datadogRum.init({
      ...commonConfig,
      applicationId: DD_APPLICATION_ID,
      defaultPrivacyLevel: 'mask-user-input',
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      trackFrustrations: true,
      enableExperimentalFeatures: ['clickmap'],
      allowedTracingOrigins: ALLOWED_TRACING_ORIGINS,
      sessionSampleRate: DD_SAMPLE_RATES.rum,
      sessionReplaySampleRate: DD_SAMPLE_RATES.sessionReplay,
    })

    datadogRum.stopSession()
  }

  function initSessionReplayRecorder() {
    datadogRum.startSessionReplayRecording()
  }

  function initLogs() {
    datadogLogs.init({
      ...commonConfig,
      sessionSampleRate: DD_SAMPLE_RATES.logs,
      forwardErrorsToLogs: true,
      forwardReports: 'all',
    })
  }

  function sendLog(message: string, payload?: object, level?: LogLevel) {
    datadogLogs.logger.log(message, payload, level)
  }

  function reportError(error: Error | unknown, context?: object) {
    datadogRum.addError(error, context)

    if (!isProd) {
      console.error(error)
    }
  }

  function sendEvent(eventCode: string, payload: object) {
    sendLog(
      eventCode,
      {
        eventCode,
        ...payload,
      },
      'info'
    )
  }

  function sendAction(actionName: string, payload?: object) {
    datadogRum.addAction(actionName, payload)
  }

  return {
    init,
    sendEvent,
    sendAction,
    reportError,
  }
}

const instance = Monitoring()

export { instance as Monitoring }
