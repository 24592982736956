export const APP_VERSION = process.env.APP_VERSION
export const DD_ENV = process.env.DD_ENV
export const DD_APPLICATION_ID = process.env.DD_APPLICATION_ID as string
export const DD_CLIENT_TOKEN = process.env.DD_CLIENT_TOKEN as string
export const DD_SITE = 'datadoghq.com'
export const DD_ENABLED = process.env.DD_ENABLED
export const DD_IS_SESSION_REPLAY_ENABLED =
  process.env.DD_IS_SESSION_REPLAY_ENABLED
export const PROJECT_NAME = 'ecs-lno-frontend-concierge'
export const ALLOWED_TRACING_ORIGINS = [
  'https://apigw-commons-hml.ecsbr.net',
  'https://apigw-commons-prd.ecsbr.net',
  'https://api.serasa.dev',
  'https://api.serasa.com.br',
  'https://api-auth.serasa.com.br',
]

const DD_RUM_SAMPLE_RATE = process.env.DD_RUM_SAMPLE_RATE
const DD_SESSION_REPLAY_SAMPLE_RATE = process.env.DD_SESSION_REPLAY_SAMPLE_RATE
const DD_LOGS_SESSION_SAMPLE_RATE = process.env.DD_LOGS_SESSION_SAMPLE_RATE
const DD_DEFAULT_SAMPLE_RATE = 100

export const DD_SAMPLE_RATES = {
  rum: Number(DD_RUM_SAMPLE_RATE),
  sessionReplay: Number(DD_SESSION_REPLAY_SAMPLE_RATE),
  logs: Number(DD_LOGS_SESSION_SAMPLE_RATE),
  default: Number(DD_DEFAULT_SAMPLE_RATE),
}
